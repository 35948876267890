import React from "react";
import PropTypes from "prop-types";

function BooleanToggleInput({ questionName, value, onChange, onBlur, locked }) {
  const handleUpdate = (e) => {
    if (locked) return; // Prevent changes when disabled
    onChange(JSON.parse(e.target.value));
    setTimeout(() => {
      onBlur(e.target.value); // trigger a blur on change because there is no blur normally
    }, 0);
  };

  return (
    <span className="boolean_toggle">
      <span className="toggle_background"></span>
      <label className={`left toggle_label ${locked ? "disabled" : ""}`}>
        <input
          type="radio"
          name={questionName}
          value="true"
          checked={JSON.parse(value) === true}
          onChange={handleUpdate}
          disabled={locked}
        />
        <span className="slider"></span>
        Yes
      </label>

      <label className={`right toggle_label ${locked ? "disabled" : ""}`}>
        <input
          type="radio"
          name={questionName}
          value="false"
          checked={JSON.parse(value) === false}
          onChange={handleUpdate}
          disabled={locked}
        />
        <span className="slider"></span>
        No
      </label>
    </span>
  );
}

BooleanToggleInput.propTypes = {
  questionName: PropTypes.string.isRequired,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  locked: PropTypes.bool,
};

BooleanToggleInput.defaultProps = {
  value: null,
  locked: false,
};

export default BooleanToggleInput;
